import React from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { Button } from "antd";
import { FileExcelOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const exportToExcel = (filename, data, fields, showPeriod) => {
    const worksheetData = data.map((item) => {
        const row = {};
        fields.forEach((field) => {
            row[field.title] = item[field.dataIndex];
        });
        return row;
    });

    if (showPeriod) {
        worksheetData.unshift({ [`Период ${showPeriod[0]} - ${showPeriod[1]}`]: "" });
    }

    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

    saveAs(dataBlob, `${filename}.xlsx`);
};

export default function ExportExcel({ filename, data, fields, style, showPeriod }) {
    const { t } = useTranslation();

    return (
        <Button
            type="primary"
            icon={<FileExcelOutlined />}
            style={style}
            size="small"
            onClick={() => exportToExcel(filename, data, fields, showPeriod)}
        >
            {t("component.export")}
        </Button>
    );
}
